<template lang="pug">
.-big-table
  .ui.inverted.active.dimmer(v-if='isCohortInitialized && nVariants === 0')
    .content
      h2.ui.grey.header No Results
        .sub.grey.header Your search returned no results

  .-status
    | {{ statusText }}
    .-download(v-if='isCohortInitialized && nVariants < downloadLimit')
      //! faq anchor not work
      div(v-if='nRequests') Creating download links...  (#[router-link(to='/faq',target='_blank',title="Why does this message appear?") ?])
      div(v-else) Download #[a(@click='download(true)',href='#') all columns] | #[a(@click='download(false)',href='#') visible columns]

  .-scroll(v-if='cohort' ref='scroll' @scroll='onScroll'): table.ui.striped.celled.table
    thead: tr
      th(v-for='column in fixedColumns',:class='columnClass(column)') {{ column }}
      th(v-for='column in selectedColumns',:class='columnClass(column)')
        i.small.filter.icon(v-if='column.filterable')
        | {{ column.name }}
    tbody
      tr(:style='paddingTop')
      tr(v-for='v in rendered.rows' :key='v["No."]')
        td(v-for='column in fixedColumns')
          // IGV integraion is disabled in the Taiwan Genomes version.  You can find the related code in commit c6db3eb.
          span {{ v[column] }}
        td(v-for='column in selectedColumns' :class='columnClass(column)')
          // IGV integraion is disabled in the Taiwan Genomes version.  You can find the related code in commit c6db3eb.
          a(v-if='column.name == "POS" && hasALT(v.ALT)' @click='toMain(v.POS)') {{ v.POS }}
          span(v-else-if='v[column.name] == 0.000000000001 || v[column.dbName] == 0.000000000001') .
          span(v-else-if='v[column.name] == 0.000000000002 || v[column.dbName] == 0.000000000002') nan
          span(v-else-if='v[column.dbName || column.name] && column.numeric') {{ Number(v[column.dbName || column.name].toFixed(6)) }}
          span(v-else) {{ v[column.dbName || column.name] }}
      tr(:style='paddingBottom')

  .-empty(v-else): .ui.small.compact.error.message Load a cohort to start
</template>

<script>
import 'babel-polyfill' // for async/await
import axios from 'axios'
import { throttle } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  activated() {
    if (this.$refs.scroll) this.$refs.scroll.scrollTop = this.scrollTop
  },

  beforeDestroy() {
    this.$root.$off('BigTable:reset', this.apply)
  },

  computed: {
    ...mapGetters([
      'cohort',
      'columns',
      'filterDetails',
      'filters',
      'nRequests',
      'nVariants',
      'selectedColumns'
    ]),
    ...mapState(['isCohortInitialized']),

    knownSize() {
      if (this.nVariants) return this.nVariants
      if (!this.loaded.rows.length) return 0
      return this.loaded.start + this.loaded.rows.length
    },

    paddingBottom() {
      // {{{
      const end = this.rendered.start + this.rendered.rows.length
      // chrome is broken at ~493000 row (row hight = 34)
      // safari is broken at 33554400 px
      const size = Math.min(this.knownSize, 400000) - end
      return { height: Math.max(size, 0) * this.rowHeight + 'px' }
    }, // }}}

    paddingTop() {
      return { height: this.rendered.start * this.rowHeight + 'px' }
    },

    statusText() {
      if (this.isCohortInitialized) return this.nVariants + ' variants'
      return 'Counting variants... (it takes tens of minutes for a new filter combination)'
      //! refactor by using multiple sql queries
      if (!this.nCountingVariants) return 'searching'
      let text = this.nCountingVariants + '+ variants'
      if (this.nCountingVariants < this.suggestedVariantLimit) return text
      return (
        text + ' (it might take a while, adding more filters is recommended)'
      )
    }
  },

  created() {
    // non-reactive data goes here {{{
    // view: cohort with a specific filter configuration
    // ofDatabase: variant index of database
    // ofView: variant index of current view
    // `this.anchors`: [
    //  { ofDatabase: 123, ofView: 100 },
    //  { ofDatabase: 246, ofView: 200 },
    //  { ofDatabase: 369, ofView: 300 }
    // ]
    this.anchors = [] // anchors of positions that have been queried
    this.fixedColumns = ['No.']
    //! map queries to nVariants, nVariants is useful for backend to decide search strategy
    // cache the number of exact matches for terms data searching
    this.matchCounts = {}
    this.rowHeight = 34
    this.routePath = this.$route.path // either '/main' or '/supplement'
    this.scrollTop = 0 // keep-alive does not recover scroll position
    this.suggestedVariantLimit = 5000

    this.$root.$on('BigTable:reset', this.reset)
  }, // }}}

  data: () => ({
    loaded: { rows: [], start: 0 }, // loaded variants
    nCountingVariants: 0,
    rendered: { rows: [], start: 0 } // rendered variants
  }),

  methods: {
    ...mapActions(['getVariants']),
    ...mapMutations(['resetFilters', 'resetRequest']),

    addAnchor(anchor) {
      // {{{
      let i = this.bisectRightOnAnchors(anchor.ofView)
      if (this.anchors[i]?.ofView == anchor.ofView) return
      this.anchors.splice(i, 0, anchor)
    }, // }}}

    columnClass: column => ({
      '-filterable': column.filterable,
      '-numeric': column.numeric
    }),

    // start/stop pseudo variant counting
    countVariants(stop = false) {
      // {{{
      clearInterval(this.variantCountingInterval)
      this.nCountingVariants = 0
      if (stop) return
      this.variantCountingInterval = setInterval(() => {
        this.nCountingVariants +=
          this.nCountingVariants < this.suggestedVariantLimit ? 100 : 50
      }, 1500)
    }, // }}}

    download(all) {
      const columns = all
        ? Object.keys(this.loaded.rows[0])
        : Object.keys(this.selectedColumns).map(
            column => this.columns[column]['dbName'] ?? column
          )
      const link = document.createElement('a')
      link.setAttribute(
        'download',
        `TaiwanGenomes_${this.filterDetails.map(f => f.detail).join(',')}.csv`
      ) // filename
      link.setAttribute(
        'href',
        encodeURI(
          `data:text/csv;charset=utf-8,${columns}\n` +
            this.loaded.rows
              .map(row => columns.map(column => row[column]).join(','))
              .join('\n')
        )
      )
      link.click()
      link.remove()
    },

    hasALT(ALT) {
      return '/supplement' == this.routePath && '.' != ALT
    },

    isFiltered(column) {
      // {{{
      return (
        column.name &&
        this.filters[column.name] &&
        this.filters[column.name].filtered
      )
    }, // }}}

    in(query, range) {
      // {{{
      return (
        query >= range.start &&
        query <= Math.min(range.start + range.rows.length, this.nVariants)
      )
    }, // }}}

    isLocus: (columnName, value) =>
      (columnName === 'MostImportantFeatureGene' || columnName === 'POS') &&
      '.' !== value,

    findNearestAnchor(start, size) {
      // {{{
      if (this.anchors.length == 0) return null
      let right = this.bisectRightOnAnchors(start)
      let leftAnchor = right ? this.anchors[right - 1] : undefined
      let rightAnchor = this.anchors[right]
      if (!leftAnchor) return rightAnchor
      if (!rightAnchor) return leftAnchor
      return start - leftAnchor.ofView < rightAnchor.ofView - start - size
        ? leftAnchor
        : rightAnchor
    }, // }}}

    // returns an insertion point which >= `target` in `this.anchors[*].ofView`
    bisectRightOnAnchors(target) {
      // {{{
      let left = 0,
        right = this.anchors.length
      while (left < right) {
        let mid = left + ((right - left) >> 1)
        if (this.anchors[mid].ofView < target) left = mid + 1
        else right = mid
      }
      return left
    }, // }}}

    async load(vr, loadSize = this.loadSize, cached = false) {
      // {{{
      // calculate buffered visible range, `bvr`
      const bvr = {
        start: Math.max(vr.start - this.visibleBuffer, 0),
        end: Math.min(vr.end + this.visibleBuffer, this.knownSize)
      }

      // check whether `bvr` has been loaded
      const bottomLoaded = this.in(bvr.end, this.loaded)
      const topLoaded = this.in(bvr.start, this.loaded)
      if (bottomLoaded && topLoaded) return // nothing to load

      // calculate load range
      let [size, start] = bottomLoaded ? [0, 0] : [loadSize, bvr.end]
      if (!topLoaded) {
        size += loadSize
        start = bvr.start
      }

      // regulate load range
      start = Math.floor(start / loadSize) * loadSize // `start` must be divisiable by `loadSize`
      if (this.nVariants) size = Math.min(size, this.nVariants - start)

      let anchor = this.findNearestAnchor(start, size),
        idQuery,
        offset,
        reverse = false
      if (!anchor) {
        offset = start
      } else if (anchor.ofView <= start) {
        idQuery = { ['id__gte']: anchor.ofDatabase }
        offset = start - anchor.ofView
      } else {
        idQuery = { ['id__lt']: anchor.ofDatabase }
        offset = anchor.ofView - (start + size)
        reverse = true
      }

      // get variants
      // the `start`-th variant of the current view equals to
      // the `offset`-th variant of database relative to the corresponding `idQuery`
      const [data, error] = await this.getVariants({
        idQuery,
        offset,
        reverse,
        size,
        matchCounts: this.matchCounts,
        cached
      })
      if (error) return this.$warn(error)
      if (data.rows.length == 0) return // nothing loaded, skip post-processing

      // add No. column
      let no = start
      for (const v of data.rows) v['No.'] = ++no

      this.matchCounts = data.matchCounts

      this.addAnchor({ ofDatabase: data.databaseStart, ofView: start })
      this.addAnchor({
        ofDatabase: data.databaseEnd + 1,
        ofView: start + data.rows.length
      })

      // update `this.loaded` {{{
      const end = Math.min(start + data.rows.length, this.nVariants)
      const loaded = this.loaded
      if (this.in(start, loaded))
        // start in between loaded range, concat
        loaded.rows.splice(start - loaded.start, data.rows.length, ...data.rows)
      else if (this.in(end, this.loaded)) {
        // end in between loaded range, concat
        loaded.rows.splice(0, end - loaded.start, ...data.rows)
        loaded.start = start
      } else {
        // no overlap, new loaded range
        loaded.rows = []
        loaded.rows.splice(0, data.rows.length, ...data.rows)
        loaded.start = start
      }
      // }}}
    }, // }}}

    onScroll: throttle(async function() {
      // {{{
      await this.load(this.visibleRange())
      this.render(this.visibleRange())
    }, 400), // }}}

    async render(vr) {
      // {{{
      // if `vr` has been rendered, skip render
      if (this.in(vr.start, this.rendered) && this.in(vr.end, this.rendered))
        return

      // `vr` should be loaded, don't call load() here to prevent side effect
      const loadedStart = this.loaded.start
      const loadedEnd = this.loaded.start + this.loaded.rows.length
      if (
        vr.start < loadedStart ||
        (vr.end > loadedEnd && vr.end < this.knownSize)
      )
        return this.$warn('Visible range has not loaded')

      // calculate render range
      const start = Math.max(
          this.loaded.start,
          Math.floor(vr.mid - this.renderedMax / 2)
        ),
        end = Math.min(
          this.loaded.start + this.loaded.rows.length,
          Math.ceil(vr.mid + this.renderedMax / 2)
        )

      // render
      this.rendered.rows = this.loaded.rows.slice(
        start - this.loaded.start,
        end - this.loaded.start
      )
      this.rendered.start = start
    }, // }}}

    async reset() {
      // {{{
      if (this.$route.path !== this.routePath) return

      this.anchors = []
      this.loaded.rows.splice(0, this.loaded.rows.length)
      this.rendered.rows.splice(0, this.rendered.rows.length)

      this.countVariants(this.isCohortInitialized)
      this.resetRequest() // note that this must be called before `this.scroll(0)`, otherwise the requests issued by `this.scroll(0)` will be reset
      this.scrollTo(0)
    }, // }}}

    scrollTo(scrollTop) {
      // {{{
      if (this.$refs.scroll) this.$refs.scroll.scrollTop = scrollTop
      this.onScroll()
    }, // }}}

    async toMain(positions) {
      await this.$router.push('main')
      this.resetFilters()
      Object.assign(this.filters.POS, {
        filtered: true,
        position: [positions]
      })
      this.$root.$emit('Filter:apply') //! events route
    },

    visibleRange() {
      // {{{
      const el = this.$refs.scroll
      const [height, top] = el ? [el.offsetHeight, el.scrollTop] : [0, 0]
      if (el) this.scrollTop = top
      const start = Math.floor(top / this.rowHeight)
      const end = Math.ceil((top + height) / this.rowHeight) - 1 // table header
      return { start, end, mid: Math.round((start + end) / 2) }
    } // }}}
  },

  props: {
    downloadLimit: { default: 1000 },

    // depend on memory
    loadedMax: { default: 1000 },

    // see `visibleBuffer`
    loadSize: { default: 100 },

    // depend on memory, make sure this is less than `loadedMax`
    renderedMax: { default: 50 },

    // visible range (VR): rows actually visible
    //  * VR is usually < 50 rows
    //  * suppose screen height ≤ 1000 px
    //  * suppose row height ≥ 20 px
    //  * visible range < 1000 / 20 = 50
    // buffered visible range (BVR): VR ± `visibleBuffer`
    //  * make sure `loadSize` > BVR
    //  * ideally, BVR should be pre-loaded
    //  * thus, load request is emitted when BVR exceeds loaded range
    // e.g. set `visibleBuffer` to 2 * VR
    //  * rows upward/downward 2 * VR, BVR ≈ 5 * VR, are pre-loaded
    //  * `visibleBuffer` = 100, `loadSize` ≥ 250
    visibleBuffer: { default: 40 }
  },

  watch: {
    // `value` becomes true when `this.nVariants` is finalized
    async isCohortInitialized(value) {
      // {{{
      // function of dynamic sample/bamfile is disabled in the Taiwan Genomes version.  You can find the related code in commit c6db3eb.
      if (!value) return
      this.countVariants(true) // stop pseudo variant counting
      if (this.nVariants && this.nVariants < this.downloadLimit) {
        await this.load(
          {
            end: this.downloadLimit,
            mid: Math.round(this.downloadLimit / 2),
            start: 0
          },
          this.downloadLimit,
          true
        ) // automatically issue an extra loading request for download
        this.render(this.visibleRange())
        this.resetRequest()
      }
    } // }}}
  }
}
</script>

<style lang="sass" scoped>
// {{{
.-big-table
  display: flex
  flex: 1 1 auto
  flex-direction: column
  margin: 1em
  position: relative

.-status
  display: flex
  justify-content: space-between
  button
    margin-left: 1em

.-scroll
  border: 1px solid rgba(34, 36, 38, 0.15)
  border-radius: 0.28571429rem
  margin-top: 1em
  overflow: auto

.-scroll .ui.table
  border: 0

  tr:nth-child(even) td
    background-color: #F9FAFB // use color without alpha for sticky columns

  tr:nth-child(odd) td
    background-color: white // use color without alpha for sticky columns

  th, td
    white-space: nowrap

    &:nth-child(1)
      box-shadow: 1px 0 0 0 #d9dadc // simulate the right border
      left: 0
      position: sticky

  th
    position: sticky
    top: 0
    z-index: 2

    &:nth-child(1)
      z-index: 3

    &.-filterable
      cursor: pointer

  td
    max-width: 12em
    overflow: hidden
    text-overflow: ellipsis

    &:hover
      white-space: normal
      word-wrap: break-word

    &:nth-child(1)
      z-index: 2

    &.-numeric
      text-align: left

    a
      cursor: pointer

.-empty
  border: 1px solid rgba(34, 36, 38, 0.15)
  border-bottom-left-radius: 0.28571429rem
  border-bottom-right-radius: 0.28571429rem
  text-align: center

  .ui.message
    margin-top: 1em
</style>
// }}}
