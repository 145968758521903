<template lang="pug">
.-side-panel(@keyup.esc='currentPanel=null' :class='{show: currentPanel!=null}')
  // https://vuejs.org/v2/guide/components.html#Dynamic-Components
  keep-alive: component(v-if='currentPanelComponent' :is="currentPanelComponent")
</template>

<script>
import 'semantic-ui-offline/semantic.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    'cohort-panel': require('./SidePanel/CohortPanel.vue').default,
    'column-panel': require('./SidePanel/ColumnPanel.vue').default,
    'filter-panel': require('./SidePanel/FilterPanel.vue').default,
    'subCohort-panel': require('./SidePanel/SubCohortPanel.vue').default
  },
  computed: {
    ...mapGetters(['currentPanel']),
    currentPanelComponent() {
      if (!this.currentPanel) return false
      return this.currentPanel + '-panel'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/variables.sass"
.-side-panel
  clip-path: inset(0 0 0 100%)
  transition: clip-path .2s
.-side-panel.show
  clip-path: inset(0 0 0 0)
</style>
